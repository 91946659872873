<template>
	<v-card>
		<s-crud
            edit
            :filter="filter"
            :config="config"
            @save="save($event)"
            title="Notificaciones WhatsApp"
        >
			<template scope="props">
				<v-row style="margin:auto" justify="center">
					<v-col cols="12" lg="3" md="3">
						<s-text label="Numero remitente" v-model="props.item.MsgNumberSender"> </s-text>
					</v-col>

					<v-col cols="12" lg="3" md="3">
						<s-text label="Numero destino" v-model="props.item.MsgNumberDestination"> </s-text>
					</v-col>
					<v-col cols="12" lg="3" md="3">
						<s-text label="Cuenta SID" v-model="props.item.accountSid"> </s-text>
					</v-col>

					<v-col cols="12" lg="3" md="3">
						<s-text label="Auth Token" v-model="props.item.authToken"> </s-text>
					</v-col>
					<v-col cols="12">
						<s-textarea
							style="margin-top: -18px"
							label="Mensaje a enviar"
                            v-model="props.item.MsgDescription"
						></s-textarea>
					</v-col>
				</v-row>
				<v-row justify="center" style="margin:auto">
                    <v-col cols="12" lg="3" md="3">
						<s-text label="Msg Configuracion" v-model="props.item.MsgConfiguration"> </s-text>
					</v-col>
					<v-col cols="4" lg="2" md="2">
						<v-checkbox style="margin-top: -5px; " label="Emitir Alerta" v-model="props.item.MsgEmitAlert">
						</v-checkbox>
					</v-col>

					<v-col cols="8" lg="2" md="2">
						<s-text
							style="margin-top: -18px; "
							label="N° veces por dia"
							number
							:min="0"
                            v-model="props.item.MsgQuantityAlert"
						>
						</s-text>
					</v-col>
				</v-row>
			</template>

            <template v-slot:SecStatus="{ row }">
                <v-chip
                    x-small
                    color="success"

                >{{row.SecStatus == 1 ? 'Activo':''}}</v-chip>
            </template>
		</s-crud>
	</v-card>
</template>

<script>
    import _sWhatsApp from "../../../services/General/WhatsAppService.js";
	export default {
        data(){
            return {
                filter:{},
                config:{
                    service: _sWhatsApp,
                    model:{
                        MgsID: "ID",
                        SecStatus:""
                    },
                    headers :[
                        {text: "Id", value: "MgsID"},
                        {text: "Remitente", value: "MsgNumberSender"},
                        {text: "Destinatario", value: "MsgNumberDestination"},
                        {text: "Mensaje", value: "MsgDescription"},
                        {text: "Cant. Alerta", value: "MsgQuantityAlert"},
                        {text: "Estado", value: "SecStatus"},

                    ]
                },
            }
        },
        methods: {

            isValidated(val){
                var isValidated = true;

                if(val.MsgNumberSender.length == 0){ 
                    this.$fun.alert("Ingrese numero remitente", "warning")
                    isValidated = false
                    return;
                }

                if(val.MsgNumberDestination.length == 0){ 
                    this.$fun.alert("Ingrese numero destino", "warning")
                    isValidated = false
                    return;
                }

                if(val.accountSid.length == 0){ 
                    this.$fun.alert("Ingrese cuenta SID", "warning")
                    isValidated = false
                    return;
                }

                if(val.authToken.length == 0){ 
                    this.$fun.alert("Ingrese Auth Token", "warning")
                    isValidated = false
                    return;
                }

                if(val.MsgDescription.length == 0){ 
                    this.$fun.alert("Ingrese un mensaje", "warning")
                    isValidated = false
                    return;
                }



                return isValidated
            },
            save(val){

                if(!this.isValidated(val)){return};

                if(val.MsgEmitAlert){
                    val.MsgEmitAlert = 1
                }else{
                    val.MsgEmitAlert = 0
                }

                val.SecStatus = 1
                val.UsrCreateID = this.$fun.getUserID()

                console.log("Guardar", val);
                val.save()
            }
        }
    };
</script>

<style></style>
